import { APP_INITIALIZER } from '@angular/core';
import { EnvironmentService } from './environment.service';

const EnvironmentFactory = (configService: EnvironmentService) => {
  return () => configService.load();
};

export const EnvironmentLoader = {
  provide: APP_INITIALIZER,
  useFactory: EnvironmentFactory,
  deps: [EnvironmentService],
  multi: true,
};
