import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import {
  NotifierModule,
  NotifierOptions,
  NotifierService,
} from 'gramli-angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './core/services/api.service';
import { CustomNotifierService } from './core/services/custom-notifier.service';
import { GlobalDataService } from './core/services/globalData.service';
import { TokenInterceptorService } from './core/services/token-interceptor.service';
import { languageInterceptorProviders } from './core/services/language/language-interceptor.provider';
import { TranslateLoaderModule } from './core/services/language/translate-loader.module';
import { EnvironmentLoader } from './core/environment/environment.loader';
import { NotFoundComponent } from './core/not-found/not-found.component';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  bootstrap: [AppComponent],
  imports: [
    TranslateLoaderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    NotifierModule,
    NotifierModule.withConfig(customNotifierOptions),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    EnvironmentLoader,
    NotifierService,
    CustomNotifierService,
    ApiService,
    GlobalDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    languageInterceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
