import { NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

import { Observable } from 'rxjs';
import {
  TranslateModule,
  TranslateService,
  TranslateLoader,
  LangChangeEvent,
} from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prod';

export class TranslateHttpLoader implements TranslateLoader {
  private httpClient: HttpClient;
  constructor(
    handler: HttpBackend,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json',
  ) {
    this.httpClient = new HttpClient(handler);
  }

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<object> {
    return this.httpClient.get(`${this.prefix}${lang}${this.suffix}`, {
      headers: new HttpHeaders(),
    });
  }
}

export function createTranslateLoader(handler: HttpBackend) {
  return new TranslateHttpLoader(handler, environment.translateFileUrl, '');
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class TranslateLoaderModule {
  constructor(private translate: TranslateService) {
    if (!localStorage.getItem('DEFAULT_LANGUAGE')) {
      localStorage.setItem('DEFAULT_LANGUAGE', 'de');
    }

    const defLang = localStorage.getItem('DEFAULT_LANGUAGE');

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(defLang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(defLang);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('DEFAULT_LANGUAGE', event.lang);
    });
  }
}
