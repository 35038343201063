<div class="loader" *ngIf="loader">
  <div>
    <h1>LOADING</h1>
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<router-outlet></router-outlet>
<notifier-container></notifier-container>
