// `environment.ts` is used during development, while the environment.prod.ts file is used when building Docker images.
// The configuration is set up at build-time, but for runtime-specific environment variables, refer to
// `assets/environment.json`.

export const environment = {
  production: true,
  translateFileUrl:
    'https://vdbum-api.vdbum-digipruef.de/langs/translation/web/',
  sentryDsn:
    'https://a62a497522d888ae3e75601ff4958a4d@o4505635306405888.ingest.us.sentry.io/4508176976904192',
};
