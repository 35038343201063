/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: 'unknown',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.httpClientIntegration(),
    ],

    // enable distributed tracing, but disable browser tracing
    tracesSampleRate: 0,

    ignoreErrors: ['Http failure response'],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
