import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseSubscribeClass } from './core/class/base-subscribe-class';
import { GlobalDataService } from './core/services/globalData.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseSubscribeClass implements OnInit {
  public loader: boolean;

  constructor(
    private globalDataService: GlobalDataService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToLoader();
  }

  public subscribeToLoader(): void {
    this.globalDataService
      .subscribeLoader()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.loader = status;
        this.cdr.detectChanges();
      });
  }
}
